@import 'style';

.headerhamburger {
	display: block;
	padding: 13px 10px;
	margin: -13px -10px;

	@include media($ipad-land) {
		display: none;
	}

	&-bar {
		display: block;
		width: 24px;
		height: 3px;
		background-color: var(--secondary);

		.mod-subnavigation & {
			background-color: black;
		}

		&:nth-child(2) {
			margin: 5px 0;
		}
	}
}
