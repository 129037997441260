@import 'style';

.mobilemenu {
	position: relative;
	z-index: 3;

	@include media($ipad-land) {
		display: none;
	}

	&-overlay {
		background-color: rgba(black, 0.5);
		position: fixed;
		inset: 0;

		&:global(.ReactModal__Overlay) {
			opacity: 0;
			transition: opacity 300ms ease-in-out;
		}

		&:global(.ReactModal__Overlay--after-open) {
			opacity: 1;
		}

		&:global(.ReactModal__Overlay--before-close) {
			opacity: 0;
		}
	}

	&-content {
		outline: none;
		width: 100%;
		overflow: auto;
		max-height: 100%;
		display: flex;
		flex-direction: column;
		background-color: white;
		border-top: 12px solid var(--primary);

		&:global(.ReactModal__Content) {
			opacity: 0;
			transform: translateX(100%);
			transition: all 300ms ease-in-out;
		}

		&:global(.ReactModal__Content--after-open) {
			opacity: 1;
			transform: translateX(0);
		}

		&:global(.ReactModal__Content--before-close) {
			pointer-events: none;
			opacity: 0;
			transform: translateX(100%);
		}
	}
}

.top {
	position: relative;
	padding: 20px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&-logo {
		svg {
			height: 26px;
			display: block;
		}
	}

	&-close {
		color: var(--secondary);
		font-size: 18px;
	}
}

.nav {
	&-parent {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 20px 30px;
		background-color: var(--secondary);
		color: white;
		font-weight: 600;
		letter-spacing: 1px;
		text-transform: uppercase;

		&-title {
			margin-left: 10px;
		}
	}

	&-item {
		padding: 20px 30px;
		border-top: 1px solid #e5e5e5;

		&-row {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-arrow {
				padding: 14px 15px;
				margin: -14px -10px -14px 0;
			}
		}
	}
}

.link {
	position: relative;
	font-weight: 600;
	line-height: 1.25;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: color 0.3s ease-out;

	&.is-active {
		color: var(--secondary);
	}
}
